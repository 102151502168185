import * as React from 'react';
import { navigate } from 'gatsby'
import Layout from '../../components/Layout';
import Footer from '../../components/Footer';
import SingleBlog from '../../modules/blog/SingleBlog';
import { Helmet } from 'react-helmet';
import Blogs from '../../modules/blog/posts'

const isBrowser = typeof window !== "undefined"

const Clients = ({params}) => {
  if ((!params.id || !Blogs[params.id]) && isBrowser) {
    navigate('/');
    return;
  }

  return (
    <Layout>
      <SingleBlog id={params.id} />
      <Footer />
    </Layout>
  );
};

export default Clients;
